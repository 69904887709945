import React, { useState } from 'react';
import axios from 'axios';

const Register = () => {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        confirm_password: '',
        role: 'student',
        age: '',
        grade: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/auth/register/', formData);
            alert('Регистрация успешна!');
            console.log(response.data);
        } catch (error) {
            alert('Ошибка регистрации: ' + error.response?.data?.message);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="text"
                name="username"
                placeholder="Имя пользователя"
                value={formData.username}
                onChange={handleChange}
                required
            />
            <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
            />
            <input
                type="password"
                name="password"
                placeholder="Пароль"
                value={formData.password}
                onChange={handleChange}
                required
            />
            <input
                type="password"
                name="confirm_password"
                placeholder="Подтвердите пароль"
                value={formData.confirm_password}
                onChange={handleChange}
                required
            />
            <select name="role" value={formData.role} onChange={handleChange}>
                <option value="student">Ученик</option>
                <option value="parent">Родитель</option>
                <option value="admin">Администратор</option>
            </select>
            <input
                type="number"
                name="age"
                placeholder="Возраст"
                value={formData.age}
                onChange={handleChange}
            />
            <input
                type="text"
                name="grade"
                placeholder="Класс"
                value={formData.grade}
                onChange={handleChange}
            />
            <button type="submit">Зарегистрироваться</button>
        </form>
    );
};

export default Register;
