import React, { useState } from 'react';
import axios from 'axios';

const Login = () => {
    const [credentials, setCredentials] = useState({
        username: '',
        password: ''
    });

    const handleChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/auth/login/', credentials);
            localStorage.setItem('token', response.data.token); // Сохраняем токен
            alert('Авторизация успешна!');
            console.log(response.data);
        } catch (error) {
            alert('Ошибка авторизации: ' + error.response?.data?.error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="email"
                name="email"
                placeholder="Email"
                value={credentials.email}
                onChange={handleChange}
                required
            />
            <input
                type="password"
                name="password"
                placeholder="Пароль"
                value={credentials.password}
                onChange={handleChange}
                required
            />
            <button type="submit">Войти</button>
        </form>
    );
};

export default Login;
