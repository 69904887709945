import React, { useEffect, useState } from 'react';
import { getLessons } from '../api';

const LessonList = () => {
    const [lessons, setLessons] = useState([]);

    useEffect(() => {
        const fetchLessons = async () => {
            const response = await getLessons();
            setLessons(response.data);
        };
        fetchLessons();
    }, []);

    return (
        <div>
            <h2>Список занятий</h2>
            <ul>
                {lessons.map((lesson) => (
                    <li key={lesson.id}>
                        <h3>{lesson.title}</h3>
                        <p>{lesson.description}</p>
                        <ul>
                            {lesson.tasks.map((task) => (
                                <li key={task.id}>{task.type}</li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default LessonList;

