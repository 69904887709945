import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import LessonList from './components/LessonList';
import Task from './components/Task';
import Register from './components/Register';
import Login from './components/Login';

const App = () => {
    return (
        <Router>
            <Header />
            <Routes>
                <Route path="/" element={<h1>Главная страница</h1>} />
                <Route path="/lessons" element={<LessonList />} />
                <Route path="/task/:id" element={<Task />} />
                <Route path="/progress" element={<h1>Прогресс</h1>} />
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
            </Routes>
        </Router>
    );
};

export default App;

