import axios from 'axios';

const API_URL = 'http://razvivaika.online'; // Замените на ваш домен

export const registerUser = async (userData) => {
    return await axios.post(`${API_URL}/auth/register/`, userData);
};

export const loginUser = async (credentials) => {
    return await axios.post(`${API_URL}/auth/login/`, credentials);
};

export const getLessons = async () => {
    return await axios.get(`${API_URL}/lessons/`);
};

export const submitTask = async (taskId, score, token) => {
    return await axios.post(
        `${API_URL}/progress/submit/`,
        { task_id: taskId, score },
        { headers: { Authorization: `Bearer ${token}` } }
    );
};

