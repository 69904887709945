import React, { useState } from 'react';
import { submitTask } from '../api';

const Task = ({ taskId }) => {
    const [score, setScore] = useState(0);

    const handleSubmit = async () => {
        const token = localStorage.getItem('token'); // Получаем токен из локального хранилища
        await submitTask(taskId, score, token);
        alert('Задание выполнено!');
    };

    return (
        <div>
            <h3>Выполнение задания</h3>
            <input
                type="number"
                value={score}
                onChange={(e) => setScore(e.target.value)}
                placeholder="Введите баллы"
            />
            <button onClick={handleSubmit}>Отправить</button>
        </div>
    );
};

export default Task;

