import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <header>
            <nav>
                <Link to="/">Главная</Link>
                <Link to="/lessons">Занятия</Link>
                <Link to="/progress">Прогресс</Link>
            </nav>
        </header>
    );
};

export default Header;
